
import * as Sentry from '@sentry/browser';

let isInitialized = false;

const isAppInitialized = () => !!window.getEnvironment;

const getEnvironmentName = env => env ? env.toLocaleLowerCase() : 'unknown';

const init = () => {
  // Sentry is initialized when this file is loaded and when getClient is called from other files
  if (isInitialized || !isAppInitialized()) {
    return;
  }
  if (window.getEnvironment() !== 'PRODUCTION') {
    return;
  }
  isInitialized = true;
  Sentry.init({
    dsn: 'https://eb8053844d80419cb1f06eaa62534e3a@o362124.ingest.sentry.io/6178429',
    environment: getEnvironmentName(window.getEnvironment()),
    initialScope: {
      tags: {
        layer: 'frontend',
      },
    },
    release: '2025.02.10.304b6a9',
  });
};

init();

export const getClient = () => {
  init();

  return Sentry;
};
